.section {
    position: relative;
}

.content {
    min-width: 180px;

    font-weight: 600;
    font-size: 0.75rem;
}

.content {
    font-weight: 400;
}

.header {
    width: 100%;
    padding: 10px 10px 5px;
}

.header {
    display: flex;
    align-items: center;
    padding: 10px 10px;
}

.headerPadding {
    padding-top: 12px;
}

.footerPadding {
    padding-bottom: 12px;
}

.row {
    padding: 0px 10px 0px;

    line-height: 2;
}

.row {
    display: flex;
    justify-content: space-between;
}

.playerIcon {
    display: inline-block;

    vertical-align: middle;
}

.playerName {
    display: inline-block;
    max-width: 120px;
    overflow-x: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.teamPlayer {
    color: var(--forColor);
}

.opponentPlayer {
    color: var(--againstColor);
}

.teamPlayer {
    color: #ffffff;
}

.opponentPlayer {
    color: #ffffff;
}

.rowHeader {
    color: #a6b1c4;
}

.rowHeader {
    color: rgba(255, 255, 255, 0.8);
}

:global(.darcula) .rowHeader {
    color: rgba(255, 255, 255, 0.7);
}

.rowValue {
    color: #707c93;
}

.rowValue {
    color: rgba(255, 255, 255, 0.9);
}

:global(.darcula) .rowValue {
    color: rgba(255, 255, 255, 0.9);
}

.rowValue.rowLinkValue {
    color: var(--uiPrimaryColor);
}

.row:nth-child(odd) {
    background-color: #052c4d;
}

:global(.hockeyTech) .row:nth-child(odd) {
    background-color: var(--uiPrimaryColor);
}

:global(.darcula) .row:nth-child(odd) {
    background-color: #273644;
}

.footer {
    padding: 0px 10px 0px;

    border-top: 1px solid var(--uiPrimaryTextHighlighted);

    color: var(--uiPrimaryColor);
    line-height: 3;
    text-transform: uppercase;

    cursor: pointer;
}

.footer {
    padding: 0 10px;

    border-top: 1px solid rgba(255, 255, 255, 0.51);
}

.footer.footerDisabled {
    color: #a6b0c4;
}
