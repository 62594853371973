.root {
    position: relative;

    width: 100%;
    overflow: hidden;
}

.filterPanel {
    display: flex;
    justify-content: flex-start;
    padding-inline: 16px;
}

.filterWrap {
    display: flex;
    gap: 8px;
}

.selected {
    background-color: var(--optionsDisabledColor);
}

.legend {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    min-width: 265px;
    max-width: 100%;
    margin: 6px auto 6px;
    padding-inline: 12px;
}

.legendOpen {
    align-items: flex-start;
}

.legendRow {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 265px;
}

.playersList {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    width: 100%;
    max-height: 150px;
    overflow: auto;

    font-size: 12px;
    padding-inline: 4px;
}

.player {
    max-width: 150px;
    height: 18px;
    overflow: hidden;

    border-radius: 8px;

    box-shadow: 0 0 0 2px var(--uiPrimaryColorTransparent);
    padding-inline: 4px;
}

.goodFaceOffStat {
    fill: #edf4fc;
}

:global(.darcula) .goodFaceOffStat {
    fill: rgba(41, 103, 176, 0.5);
}

.badFaceOffStat {
    fill: #fbedf0;
}

:global(.darcula) .badFaceOffStat {
    fill: rgba(217, 83, 79, 0.5);
}

.equalityFaceOffStat {
    fill: #e7e7e7;
}

:global(.darcula) .equalityFaceOffStat {
    fill: rgba(126, 126, 126, 0.5);
}

.rate {
    font-size: 18px;

    user-select: none;
    fill: #4d4d4d;
    text-anchor: middle;
}

:global(.darcula) .rate {
    fill: rgba(255, 255, 255, 0.6);
}

.slash {
    user-select: none;
    fill: #b3b3b3;
    text-anchor: middle;
}

.successCount {
    user-select: none;
    fill: var(--forColor);
    text-anchor: end;
}

.failCount {
    user-select: none;
    fill: var(--againstColor);
    text-anchor: start;
}

.totalTitle {
    font-size: 10px;

    user-select: none;
    fill: #4d4d4d;
    text-anchor: middle;
}

:global(.darcula) .totalTitle {
    fill: rgba(255, 255, 255, 0.8);
}

.zoneTitle {
    user-select: none;
    fill: rgba(0, 0, 0, 0.6);
}

:global(.darcula) .zoneTitle {
    fill: rgba(255, 255, 255, 0.6);
}

.chosenFaceOffStat {
    cursor: pointer;

    fill: rgba(80, 147, 238, 0.25);
}

.empty {
    cursor: default;

    fill: #e7e7e7;
}

:global(.darcula) .empty {
    cursor: default;

    fill: rgba(126, 126, 126, 0.5);
}

.hover:hover {
    cursor: pointer;
}
