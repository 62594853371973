.root {
    display: flex;
    justify-content: center;
    margin: 12px 0px;
}

.root:first-child {
    border-radius: 4px 0px 0px 4px;
}

.root:last-child {
    border-radius: 0px 4px 4px 0px;
}

.pageLink {
    margin-right: 5px;
    padding: 2px 6px;

    border: 1px solid #ddd;
    border-radius: 8px;

    color: var(--uiPrimaryColor);
    font-size: 12px;

    cursor: pointer;

    user-select: none;
}

:global(.darcula) .pageLink {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.pageLink:hover,
.activeLink {
    color: #fff;

    background-color: var(--uiPrimaryColor);
}

:global(.hockeyTech) .pageLink:hover {
    background-color: var(--uiPrimaryColorActive);
}
