.unbounded {
    width: 100%;
    height: 48px;
    margin-bottom: 17px;
    padding: 0 20px;

    border: 1px solid #979797;
    border-radius: 24px;

    color: #3e6373;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;

    background-color: #f9f9f9;
    outline: none;
}

.unbounded {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

:global(.darcula) .unbounded {
    border: 1px solid #1f232f;

    color: #111;

    background-color: #d0d0d0;
}

/* common styles for input */

.input {
    height: 36px;
}

:global(.darcula) .input {
    color: white;

    background-color: #2d3d4d;
}

.input:focus {
    border: 1px solid #5cb3fd;
}

.input {
    font-weight: 300;
    font-size: 12px;
    font-family: Roboto, 'Open Sans', sans-serif;
}
