.btn {
    display: inline-block;
    padding: 6px 0.5rem;

    border: none;
    border-radius: 100px;

    font-weight: 600;
    font-size: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;

    outline: none;
    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;
}
/*stylelint-disable*/
.btn {
    border-radius: 4px;
}

.btn:focus:not([disabled]),
.btn:hover:not([disabled]) {
    text-decoration: none;

    outline: none;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.btn:active:not([disabled]) {
    text-decoration: none;

    outline: none;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.btn-default {
    color: #fff;

    background-color: #a7aac4;
}

.btn-danger {
    color: #fff;

    background-color: var(--cancelColor);
}

:global(.darcula) .btn-default {
    color: rgba(255, 255, 255, 0.7);

    background-color: #2d3d4d;
}

.btn:disabled {
    background-color: #a7aac4;
    cursor: not-allowed;
}

:global(.darcula) .btn:disabled {
    color: #c2c6c7;

    background-color: #7979798f;
}

.btn-primary {
    color: #fff;

    background-color: var(--uiPrimaryColor);

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-secondary {
    color: var(--uiPrimaryColor);

    background-color: var(--backgroundColorSecondary);

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

:global(.darcula) .btn-primary {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;

    background-color: var(--uiPrimaryColorActive);
}

.btn-active {
    color: #fff;

    background-color: var(--uiPrimaryColorActive);

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-primary:hover:not([disabled]) {
    color: #fff;

    background-color: var(--uiPrimaryColorActive);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

:global(.darcula) .btn-primary:hover:not([disabled]) {
    color: rgba(255, 255, 255, 0.9);

    background-color: var(--uiPrimaryColorActive);
}

.btn-primary:disabled {
    background-color: #a7aac4;
}

:global(.hockeyTech) .btn:disabled {
    color: var(--mediumGrayColor);

    background-color: transparent;
}

.btn-primary:active:not([disabled]) {
    color: #fff;

    background: var(--uiPrimaryColor);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
/*stylelint-enable*/
.btn-outline-default {
    border: 1px solid #ccc;

    color: #ccc;

    background: transparent none;
}

.btn-outline-white {
    border: 1px solid #fff;

    color: #fff;

    background: transparent none;
}

.btn-outline-primary {
    border: 1px solid var(--uiPrimaryColor);

    color: var(--uiPrimaryColor);

    background: transparent none;
}

.btn-outline-danger {
    border: 1px solid #d9534f;

    color: #d9534f;

    background: transparent none;
}

.btn-withoutBorder {
    border: none;
}
