.panel {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100 - 96px);
    padding: initial;

    background-color: var(--backgroundColor);
}

.contrastPanel {
    background-color: var(--backgroundColorSecondary);
}

.panelProspectMode {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100 - 96px);
    padding: initial;

    background-color: var(--backgroundColorSecondary);
}

.panelBody > div {
    margin: 0 1rem;
}

@media (max-width: 500px) {
    .panelBody > div {
        margin-inline: 5px;
    }
}

:global(.marketing) .panel {
    background-image: linear-gradient(to bottom right, #9ae8da, #6e7ce7);
}

.panelHeader {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.875rem 0.75rem;
}

.title {
    color: #a6b0c4;
    font-weight: 600;
    font-size: 1rem;
}

.game {
    color: #a8afbe;
    font-size: 1rem;
}

.matchSwitcher {
    margin-left: auto;
}

.panelBody {
    flex: 1 0 auto;
    width: 100%;
    min-width: 100%;
    max-width: calc(100vw - 32px);
    /*margin: 0.75rem auto;*/
    /*padding: 0 1.875rem;*/
    /*min-height: calc(100% - 96px);*/
    margin: initial;
    padding: initial;
}

.withBottomPadding {
    padding: 0 0 30px;
}

.withPadding {
    padding: 30px 0;
}

@media print {
    .panel {
        background: none;
    }
}
