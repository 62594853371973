.root {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    padding: 10px 20px;

    border-radius: 4px;

    background: #f9f9f9;
}

.root {
    background-color: #f6f8fb;
}

.isRangeFullWidth {
    width: 100%;
}

:global(.darcula) .root {
    background: #2d3d4d;
}

.title {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    text-transform: uppercase;
}

:global(.darcula) .title {
    color: rgba(255, 255, 255, 0.6);
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;

    color: rgba(1, 1, 1, 0.26);
}

.value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 20px;

    border: 1px solid var(--uiPrimaryColor);
    border-radius: 4px;

    color: var(--uiPrimaryColor);
    font-size: 12px;
    text-align: center;
}

:global(.darcula) .value {
    color: rgba(255, 255, 255, 0.8);

    background: #2b3136;
}

.invalidInput {
    border: 1px solid #f44e72;

    color: var(--cancelColor);
}

.invalidInput:focus {
    outline: 1px solid #f44e72;
}
