.mapWrap {
    padding: 1rem 0;
}

.map {
    display: block;
    width: 100%;
    margin: auto;
}

.field {
    fill: #fff;
}

:global(.darcula) .field {
    fill: #172027;
    stroke: #2d3337;
}

.centerCircle {
    fill: #fff;
    stroke: var(--uiPrimaryTextHighlighted);
    stroke-width: 1px;
}

:global(.darcula) .centerCircle {
    fill: #172027;
    stroke: #2d3337;
}

.faceoffCircle {
    fill: var(--uiPrimaryTextHighlighted);
}

:global(.darcula) .faceoffCircle {
    fill: #2d3337;
}

.line {
    stroke: var(--uiPrimaryTextHighlighted);
    stroke-width: 1px;
}

:global(.darcula) .line {
    stroke: #2d3337;
}

.rectDecoration {
    fill: none;
    stroke: var(--uiPrimaryTextHighlighted);
    stroke-width: 1px;
}

:global(.darcula) .rectDecoration {
    stroke: #2d3337;
}

.forGates {
    fill: var(--forColorGates);
}

.againstGates {
    fill: var(--againstColorGates);
}

.rinkStroke {
    stroke: var(--uiPrimaryTextHighlighted);
}
