.header {
    min-width: 180px;
}

.select {
    width: min-content;
    margin: 0;
    padding: 0;
}

.label {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
