.base {
    margin-right: 1rem;
    padding: 0 1rem;
}

.dropdown {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -2;

    display: none;
    min-width: 260px;
    padding: 40px 20px 0;

    background: #fff;
    cursor: default;
}

.dropdown-footer {
    margin: 0 -20px;
    margin-top: 25px;
    padding: 0 20px;

    border-top: 1px solid #9b9b9b;

    color: #000000;
    font-size: 18px;
    /*stylelint-disable*/
    font-family: 'Open Sans', sans-serif;
    line-height: 56px;
    text-align: right;

    background-color: #f1f1f1;
}

.active-dropdown {
    z-index: 40;

    display: block;
}

.profile {
    position: relative;
}

.user-name {
    margin-bottom: 20px;

    color: #4a4a4a;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
}

.user-email {
    color: #4a4a4a;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    line-height: 19px;
}

.logout-link,
.logout-link:active,
.logout-link:focus,
.logout-link:hover {
    color: #000000;
    text-decoration: none;
}

.caret {
    position: absolute;
    top: 7px;
    right: 15px;

    padding: 7px;

    cursor: pointer;
}

.caret:after {
    display: block;
    width: 14px;
    height: 13px;

    border-top: 2px solid #9b9b9b;
    border-right: 2px solid #9b9b9b;

    transform: rotate(-45deg) translateY(50%);
    content: '';
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-right: 10px;
    overflow: hidden;

    border-radius: 50%;

    background: white;
}

.profileRoot {
    position: relative;

    display: flex;
    align-items: center;
}

@media print {
    .profileRoot {
        display: none;
    }
}

.popupMenu {
    position: absolute;
    top: 0;
    right: -2px;
    z-index: 10000;

    min-width: 270px;

    border-radius: 15px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.profilePanel {
    display: flex;
    flex-direction: row;
    justify-content: center;

    cursor: pointer;
}

.popupMenu {
    top: 50px;
    right: 0;

    border-radius: 8px;
}

:global(.darcula) .popupMenu {
    background-color: #26333c;
}

.teamItem {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 10px;
}

.teamLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-left: 15px;
    overflow: hidden;

    border-radius: 50%;
}

.teamItemLogo {
    width: 34px;
    height: auto;
}

.teamName {
    margin-left: 10px;

    color: var(--uiPrimaryColor);
    font-weight: 400;
    font-size: 16px;
}

:global(.darcula) .teamName {
    color: #fff;
}

.profileControls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileControlsWrap {
    width: calc(100% - 20px);
    height: 1px;
    margin: 3px auto;
    padding-bottom: 3px;

    border-top: solid 1px #a6b1c447;
}

.profileProp {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    margin: 10px;
}

.favoriteWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: 10px;
}

.propTitle {
    color: #a6b1c4;
    font-weight: 500;
    font-size: 14px;
}

.propTitle {
    color: rgba(0, 0, 0, 0.6);
}

:global(.darcula) .propTitle {
    color: rgba(255, 255, 255, 0.6);
}

.radioToggle {
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: auto;
    padding: 3px;

    border-radius: 5px;

    background-color: #f0f0f0;
    cursor: pointer;
}

:global(.darcula) .radioToggle {
    background-color: #2d3c4d;
}

.activeRadio {
    width: 60px;
    height: 24px;

    border-radius: 3px;

    color: #fff;
    font-size: 12px;
    line-height: 24px;
    /*margin-right: 5px;*/
    text-align: center;

    background-color: var(--uiPrimaryColor);
}

:global(.darcula) .activeRadio {
    background-color: #26333c;
}

.radio {
    width: 60px;
    height: 24px;

    color: #979ea6;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
}

.controlsButtons {
    display: flex;
    width: calc(100% - 20px);
    margin: 15px 0 10px;
}

.btnLogout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 24px;
    margin-left: auto;

    border-radius: 10px;

    text-align: center;

    background-color: #a5b0c4;
    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.btnLogout {
    border-radius: 4px;
}

:global(.darcula) .btnLogout {
    background-color: #3a4047;
}
/*stylelint-enable*/

.btnLogout:hover {
    background-color: var(--cancelColor);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.profileName {
    align-self: center;

    color: rgba(255, 255, 255, 0.9);

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.profileName:hover {
    color: #fff;
    text-decoration: underline;
}

.glossaryContainer {
    margin: 0 15px;

    color: rgba(255, 255, 255, 0.9);
}

.glossaryContainer:hover {
    color: #fff;
    text-decoration: underline;
}

.uploadClientGameButton {
    display: block;
    width: calc(100% - 20px);
    margin: 10px auto 10px;

    font-size: 14px;
    text-decoration: underline;
}

.emails {
    display: flex;
    align-items: center;
    width: 80%;
    padding: 6px;
}

.email {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--uiPrimaryColor);
}

.emailLabel {
    width: 80%;

    overflow: hidden;

    color: var(--defaultColor);
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-inline: 8px;
}

.password {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    padding: 6px;
}

.manageFavorite {
    width: 70%;
}
