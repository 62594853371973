@font-face {
    font-weight: 300;
    font-family: 'Roboto Condensed';
    font-style: normal;
    src: url('../fonts/subset-RobotoCondensed-Light.woff2') format('woff2'),
        url('../fonts/subset-RobotoCondensed-Light.woff') format('woff');
}

@font-face {
    font-weight: bold;
    font-family: 'Roboto Condensed';
    font-style: normal;
    src: url('../fonts/subset-RobotoCondensed-Bold.woff2') format('woff2'),
        url('../fonts/subset-RobotoCondensed-Bold.woff') format('woff');
}

@font-face {
    font-weight: normal;
    font-family: 'Roboto Condensed';
    font-style: normal;
    src: url('../fonts/subset-Roboto-Regular.woff2') format('woff2'),
        url('../fonts/subset-RobotoCondensed-Regular.woff') format('woff');
}

@font-face {
    font-weight: 300;
    font-family: Roboto;
    font-style: normal;
    src: url('../fonts/subset-Roboto-Light.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Light.woff') format('woff');
}

@font-face {
    font-weight: bold;
    font-family: Roboto;
    font-style: normal;
    src: url('../fonts/subset-Roboto-Bold.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Bold.woff') format('woff');
}

@font-face {
    font-weight: 500;
    font-family: Roboto;
    font-style: normal;
    src: url('../fonts/subset-Roboto-Medium.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Medium.woff') format('woff');
}

@font-face {
    font-weight: 200;
    font-family: Roboto;
    font-style: normal;
    src: url('../fonts/subset-Roboto-Thin.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Thin.woff') format('woff');
}

@font-face {
    font-weight: normal;
    font-family: Roboto;
    font-style: normal;
    src: url('../fonts/subset-Roboto-Regular.woff2') format('woff2'),
        url('../fonts/subset-Roboto-Regular.woff') format('woff');
}

@font-face {
    font-weight: normal;
    font-family: Graduate;
    font-style: normal;
    src: url('../fonts/subset-Graduate-Regular.woff2') format('woff2'),
        url('../fonts/subset-Graduate-Regular.woff') format('woff');
}

@font-face {
    font-weight: normal;
    font-family: Akatab;
    font-style: normal;
    src: url('../fonts/Akatab-Regular.woff2') format('woff2'), url('../fonts/Akatab-Black.woff') format('woff');
}
