:global(.darcula) .navBar {
    background-color: #141d23;
}

.topNav {
    width: 800px;
}

.topNav {
    width: initial;
}

.item {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: -2px;
    padding: 0 1.25rem;

    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;

    cursor: pointer;
}

.item:visited,
.item:link {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;

    cursor: pointer;
}

.item.active {
    background-color: var(--uiPrimaryColor);
}

.item.active {
    color: var(--uiPrimaryColor);

    background-color: inherit;
}

.item:hover {
    color: rgb(149, 196, 251);
}

:global(.hockeyTech) .item:hover {
    color: var(--uiPrimaryColorActive);
}

.item_disabled {
    color: #aaa;
}

.nav-right {
    width: 250px;

    cursor: default;
}

@media print {
    .navBar {
        display: none;
    }
}
