.relativeWrapper {
    position: relative;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;

    border-radius: 12px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
/*stylelint-disable*/
:global(.darcula) .content {
    background-color: #2d3d4d;
}

:global(.darcula) .content input {
    background-color: #2d3d4d;
}
/*stylelint-enable*/
.hidden {
    visibility: hidden;
}

.panelOpen {
    float: right;

    font-size: 0.75rem;
    text-transform: none;
}

.menu {
    margin: 0;
    padding: 0;

    border: none;

    color: #a6b1c4;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 2;
    text-transform: none;

    list-style: none;
    outline: none;

    user-select: none;
}

.menu > li {
    cursor: pointer;
}

.menu > li:hover {
    color: var(--uiPrimaryColor);
}

.menu > li:hover:before {
    color: var(--uiPrimaryColor);
    content: '+ ';
}

.selected {
    color: var(--uiPrimaryColor);
}

.ignored {
    color: #a6b1c4;
}

.menu > .ignored:hover:before {
    color: #a6b1c4;
    content: '+ ';
}

.ignoredSelected {
    color: #707c93;
}

.menu > .ignoredSelected:hover:before {
    color: #707c93;
    content: '- ';
}

.wrapped {
    margin-right: 0.5rem;
    margin-bottom: 3px;
    padding-bottom: 3px;

    border-bottom: solid 1px #a6b1c4;
    /*color: #4990e2;*/
}

.menu > .selected:hover {
    color: var(--cancelColor);
}

.menu > .selected:hover:before {
    color: var(--cancelColor);
    content: '- ';
}

.searchPanel {
    display: flex;
    margin-right: 0.5rem;
    padding-bottom: 3px;

    border-bottom: solid 1px #a6b1c4;

    font-size: 0.75rem;
}

.searchInput {
    width: 100%;
    margin-left: 0.25rem;

    border: none;

    line-height: 0;

    outline: none;
}

.searchInput::placeholder {
    color: #a6b1c4;
}
