.filterPanel {
    display: flex;
    justify-content: flex-start;
    padding-inline: 16px;
}

.filterWrap {
    display: flex;
    gap: 8px;
}

.selected {
    background-color: var(--optionsDisabledColor);
}
