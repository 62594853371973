.submenu {
    width: 0;
    height: 0px;

    visibility: hidden;
}

.submenuShown {
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 1200;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    min-width: 126px;
    height: 220px;
    padding: 9px 16px 9px;

    border-radius: 12px;

    color: #a6b0c4;
    font-weight: 600;
    font-size: 16px;

    list-style: none;
    background-color: var(--backgroundColorSecondary);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    visibility: visible;

    transition: height 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/*stylelint-disable*/
:global(.darcula) .submenuShown {
    background-color: #26333c;
}
/*stylelint-enable*/

.submenuLink {
    color: #a6b0c4;

    list-style: none;
    cursor: pointer;
}

.submenuItem {
    overflow: hidden;
}

.submenuLinkActive.submenuLink {
    color: var(--uiPrimaryColor);

    cursor: pointer;
}
/*stylelint-disable*/
:global(.darcula) .submenuLinkActive.submenuLink {
    color: #fff;
}
/*stylelint-enable*/

.submenuLink:focus,
.submenuLink:hover,
.submenuLinkActive:focus,
.submenuLinkActive:hover {
    color: var(--uiPrimaryColorActive);
    text-decoration: none;
}
/*stylelint-disable*/
:global(.darcula) .submenuLink:focus,
:global(.darcula) .submenuLink:hover,
:global(.darcula) .submenuLinkActive:focus,
:global(.darcula) .submenuLinkActive:hover {
    color: #fff;
    text-decoration: none;
}
/*stylelint-enable*/
