@font-face {
    font-weight: normal;
    font-family: 'fontello';
    font-style: normal;
    src: url('./font/fontello.woff2?56314868') format('woff2'), url('./font/fontello.woff?56314868') format('woff'),
        url('./font/fontello.ttf?56314868') format('truetype');
}

.icon {
    display: inline-block;

    font: normal normal normal 1em fontello;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pulse {
    animation: spin 1s infinite steps(8);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.size-2x {
    font-size: 2em;
}

.size-3x {
    font-size: 3em;
}

.size-4x {
    font-size: 4em;
}

.size-5x {
    font-size: 5em;
}

.ok:before {
    content: '\e800';
}
.pencil:before {
    content: '\e801';
}
.cancel:before {
    content: '\e802';
}
.upload:before {
    content: '\e803';
}
.home:before {
    content: '\e804';
}
.down-open:before {
    content: '\e805';
}
.down-open-mini:before {
    content: '\e806';
}
.down-open-big:before {
    content: '\e807';
}
.spinner:before {
    content: '\f110';
}
.trash:before {
    content: '\f1f8';
}

.wrapper {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-around;
    align-items: center;
}
