.main,
.success {
    display: flex;
    gap: 16px;
    min-width: 360px;
}

.success {
    flex-direction: column;
}

.closeSuccess {
    width: 100%;
}

.save {
    height: 36px;
    padding-inline: 24px;
}

.buttonText {
    text-transform: none;
}

.input::placeholder {
    color: #ccc;
}

.cancel {
    height: 36px;

    border: 1px solid var(--againstColor);

    color: var(--againstColor);

    background-color: var(--backgroundColorSecondary);
}

.cancel:active,
.cancel:hover,
.cancel:focus {
    color: var(--backgroundColorSecondary);

    background-color: var(--againstColor) !important;
}

:global(.darcula) .cancel {
    color: var(--backgroundColorSecondary);

    background-color: var(--againstColor) !important;
}

@media (max-width: 768px) {
    .main {
        flex-direction: column;
        min-width: 60vw;
    }
}
