.widget {
    margin-bottom: 30px;
}

.playerFont {
    display: inline-block;
    max-width: 400px;
    overflow: hidden;

    color: #707c93;
    font-weight: 600;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: bottom;
}

/*stylelint-disable*/
:global(.darcula) .playerFont {
    color: rgba(255, 255, 255, 0.7);
}

.tableHeader {
    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    height: 40px;
    padding: 0px 5px 0px;
}

.tableHeaderItem {
    text-align: center;
}

.tableHeaderLastItem {
    overflow: hidden;

    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
}

.multipleViewHeader {
    display: flex;
    align-items: center;
}

.active {
    color: var(--uiPrimaryColor);
    font-weight: bold;
    font-size: 12px;
    line-height: 2.67;
}

.default {
    color: #a6b1c4;
    font-weight: 600;
    font-size: 12px;
    line-height: 2.67;
}

.evenRow {
    display: grid;
    grid-template-columns: 1fr 9fr;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 0px 5px 0px;

    background-color: #f9f9f9;
}

.evenRow {
    background-color: #f6f8fb;
}

:global(.darcula) .evenRow {
    color: rgba(255, 255, 255, 0.7);

    background-color: #29363e;
}

:global(.hockeyTech) .evenRow {
    background-color: var(--transparentGreenColor1);
}

.oddRow {
    display: grid;
    grid-template-columns: 1fr 9fr;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 0px 5px 0px;

    background-color: var(--backgroundColorSecondary);
}

:global(.darcula) .oddRow {
    background-color: #26333c;
}

.evenRow:hover,
.oddRow:hover {
    background: rgba(74, 144, 226, 0.15);
}

:global(.darcula) .evenRow:hover,
:global(.darcula) .oddRow:hover {
    background: rgba(41, 103, 176, 0.5);
}

:global(.hockeyTech) .evenRow:hover,
:global(.hockeyTech) .oddRow:hover {
    background: var(--transparentGreenColor2);
}

:global(.hockeyTech) .evenRow {
    background: var(--transparentGreenColor1);
}

:global(.hockeyTech) .oddRow:hover {
    background: var(--transparentGreenColor2);
}

:global(.hockeyTech) .evenRow:hover {
    background: var(--transparentGreenColor2);
}

.data {
    color: #707c93;
    font-weight: 600;
    font-size: 12px;
    line-height: 2.67;
}

:global(.darcula) .data {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
}

:global(.darcula) .data:hover {
    color: rgba(255, 255, 255, 0.9);
}

.huge {
    color: #707c93;
    font-weight: 600;
    font-size: 14px;
    line-height: 2;
}

:global(.darcula) .huge {
    color: rgba(255, 255, 255, 0.7);
}

:global(.darcula) .huge:hover {
    color: rgba(255, 255, 255, 0.9);
}

.line {
    width: 100%;
    height: 1px;

    background: var(--uiPrimaryTextHighlighted);
}

:global(.darcula) .line {
    background: #3a4047;
}
/*stylelint-enable*/
.left {
    text-align: center;
}

.danger {
    color: var(--againstColor);
    font-weight: 600;
    font-size: 14px;
}

.active2 {
    color: var(--forColor);
    font-weight: 600;
    font-size: 14px;
}

.teamLogo {
    margin: auto;
}

.multipleIndex {
    text-align: center;
}

.rowData {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    width: 120px;
}

.headerData {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 120px;
}

.headerData:after {
    content: '';

    width: 24px;
}
