.collapsedWrapper {
    position: relative;
}

.submenuShown {
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 1200;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 200px;
    min-width: 126px;
    height: auto;
    padding: 9px 16px 9px;

    border-radius: 12px;

    color: #a6b0c4;
    font-weight: 600;
    font-size: 16px;

    list-style: none;
    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    visibility: visible;

    transition: height 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.submenu {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-left: 3rem;

    color: #354759;
    font-size: 14px;

    list-style: none;
}

.collapsedWrapper .submenu {
    position: absolute;
    top: -40px;
    left: 51px;

    width: 200px;
    margin-left: 0px;
    padding-bottom: 10px;

    border-radius: 8px;

    background: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 0 0px 3px rgba(0, 0, 0, 0.1);
}
/*stylelint-disable*/
:global(.darcula) .collapsedWrapper .submenu {
    position: absolute;
    top: -38px;
    left: 52px;

    width: 200px;
    margin-left: 0px;
    padding-bottom: 10px;

    border-radius: 0px 4px 4px 0px;

    background: #2d3d4d;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12), 0 0px 3px rgba(0, 0, 0, 0.1);
}

.collapsedWrapper .title {
    padding: 10px 0px 10px;

    border-bottom: 1px solid #0000001f;

    color: var(--uiPrimaryColor);
}

.collapsedWrapper .title span {
    margin-left: 15px;

    font-weight: 600;
}

.submenuLink {
    color: #354759;

    list-style: none;
    cursor: pointer;
}

:global(.hockeyTech) .submenuLink {
    color: var(--darkGrayColor);
}

:global(.darcula) .submenuLink {
    color: rgba(255, 255, 255, 0.6);
}

.collapsedWrapper .submenuItem {
    margin-left: 15px;
    overflow: hidden;
}

.submenuItem {
    margin: 6px 0px 0px;
}

.submenuLinkActive.submenuLink {
    color: var(--uiPrimaryColor);

    cursor: pointer;
}

:global(.darcula) .submenuLinkActive.submenuLink {
    color: var(--uiPrimaryColor);
}

.submenuLink:focus,
.submenuLink:hover,
.submenuLinkActive:focus,
.submenuLinkActive:hover {
    color: var(--uiPrimaryColor);
    text-decoration: none;
}

:global(.hockeyTech) .submenuLink:hover,
:global(.hockeyTech) .submenuLinkActive:hover {
    color: var(--uiPrimaryColorActive);
}

:global(.darcula) .submenuLink:focus,
:global(.darcula) .submenuLink:hover,
:global(.darcula) .submenuLinkActive:focus,
:global(.darcula) .submenuLinkActive:hover {
    color: var(--uiPrimaryColor);
    text-decoration: none;
}
/*stylelint-enable*/
