.base {
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;

    cursor: pointer;
}
/*stylelint-disable*/
.base {
    margin-right: 10px;
}
/*stylelint-enable*/
@media print {
    .base {
        display: none;
    }
}

.container {
    position: relative;
}

/*.base::after {
    content: '';
    display: inline-block;
    margin-left: 0.25rem;
    margin-bottom: 1px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 4px 0 4px;
    border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
    -webkit-transform:rotate(360deg);
}*/

.dropdown {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 2000;
}

.langSelect {
    padding: 5px;

    border-radius: 4px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
/*stylelint-disable*/
:global(.darcula) .langSelect {
    background-color: #282d33;
}
/*stylelint-enable*/
.currentLangItem {
    display: flex;
    align-items: center;
    margin: 3px 0px;
    padding: 0 0.6875rem;

    color: var(--uiPrimaryColor);
    text-transform: capitalize;

    cursor: pointer;
}

.langItem {
    display: flex;
    align-items: center;
    margin: 3px 0px;
    padding: 0 0.6875rem;

    color: rgba(0, 0, 0, 0.7);
    text-transform: capitalize;

    cursor: pointer;
}
/*stylelint-disable*/
:global(.darcula) .langItem {
    color: rgba(255, 255, 255, 0.6);
}
/*stylelint-enable*/
.langLogo {
    width: 20px;
    height: 20px;
}

.langLogoItem {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

@media (min-width: 1024px) {
    .base {
        /* margin-right: 0.6875rem;*/
    }
}
