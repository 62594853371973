.disabled {
    fill: #a6b1c4;
}

.for {
    fill: var(--forColor);
}

.against {
    fill: var(--againstColor);
}

.forActive {
    fill: var(--forColorActive);
}

.againstActive {
    fill: var(--againstColorActive);
}

.transparentShot {
    fill: rgba(204, 204, 204, 0.25);
}

.for,
.against,
.forDisabled,
.againstDisabled,
.forActive,
.againstActive,
.transparentShot {
    cursor: pointer;

    transition: opacity 0.5s;
}

.jersey {
    cursor: pointer;

    user-select: none;
}

.defenceman {
    transform: translate(-32px, -32px);
}

.playerIcon_secondary {
    transform: scale(0.8);
}

.defenceman.playerIcon_secondary {
    transform: scale(0.8) translate(-32px, -32px);
}

@media print {
    .jersey_hidden {
        display: inline;
    }

    .playerIcon_secondary {
        transform: scale(1);
    }

    .defenceman.playerIcon_secondary {
        transform: translate(-32px, -32px);
    }
}
