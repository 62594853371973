.content {
    padding: 30px;
}

.content {
    color: #a8afbe;
    font-size: 1.5em;
}

.container {
    flex: 1;
}

.refreshButton {
    padding: 10px 20px;

    border-radius: 5px;

    font-size: 18px;
}
