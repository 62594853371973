.content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 0 1rem 0;
}

@media (max-width: 500px) {
    .content {
        padding: 0 0.5rem;
    }
}

.content {
    width: 100%;
}

.inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
