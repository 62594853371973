.frame {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;

    border-radius: 24px;

    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
}

.unbounded {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.12), 0 0 2px rgba(0, 0, 0, 0.1);
}
/*stylelint-disable*/
.frame {
    border-radius: 8px;
}

:global(.hockeyTech) .frame {
    border-radius: 0;
}

:global(.darcula) .frame {
    border-radius: 8px;

    box-shadow: none;
}

.content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    background-color: var(--backgroundColorSecondary);
}

.largeTabs {
    font-size: 16px;
    font-weight: 700;
}

.tabTitle {
    display: flex;
    align-items: center;
    padding: 0 8px 0;

    cursor: pointer;

    user-select: none;
}

.tabTitle:hover {
    color: #fff;

    cursor: pointer;
}

.tabTitle:hover {
    color: var(--uiPrimaryColor);
}

:global(.hockeyTech) .tabTitle:hover {
    color: var(--whiteColor);
}

:global(.darcula) .tabTitle:hover {
    color: rgb(149, 196, 251);
}

.activeTab {
    display: flex;
    align-items: center;
    padding: 0 8px 0;

    color: #f7f8f9;

    background: var(--uiPrimaryColorActive);
    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;
}

.activeTab {
    border-top: 2px solid var(--uiPrimaryColorActive);

    color: var(--uiPrimaryColorActive);

    background: initial;
}

:global(.hockeyTech) .activeTab {
    border-top: none;

    color: var(--whiteColor);

    background: var(--darkGrayColor);
}

:global(.darcula) .activeTab {
    border-top: 1px solid var(--uiPrimaryColor);

    color: var(--uiPrimaryColor);

    background-color: #26333c;
}

.tabContainer {
    display: flex;
    height: 100%;

    font-weight: 400;
    font-size: 1em;
}

.titleHeader {
    display: flex;
    align-items: center;
    min-height: 28px;
    margin: 5px;

    color: #ffffff;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

:global(.hockeyTech) .titleHeader {
    text-transform: none;
}

.titleHeader {
    color: rgba(0, 0, 0, 0.7);
}

:global(.hockeyTech) .titleHeader {
    color: var(--whiteColor);
}

:global(.darcula) .titleHeader {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
}

.header {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 0.1rem;

    font-size: 0.7em;

    background-color: var(--uiPrimaryColor);
}

.header {
    background-color: var(--backgroundColorSecondary);
}

@media screen and (max-width: 550px) {
    .header {
        font-size: 0.6em;
    }
}

:global(.hockeyTech) .header {
    background-color: var(--uiPrimaryColor);
}

:global(.darcula) .header {
    background-color: #26333c;
}

:global(.darcula) .header {
    color: var(--uiPrimaryTextHighlighted);

    background-color: #26333c;
}

.headerGrey {
    display: flex;
    align-items: center;
    height: 32px;
    padding-left: 0.5rem;

    font-size: 0.7em;

    background-color: #b7bdca;
}

.headerItem {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 5px;

    color: var(--uiPrimaryTextHighlighted);
    letter-spacing: 0.5px;
}

.centerAlign {
    margin: auto;
}

.rightAlign {
    margin-left: auto;
}

:global(.hockeyTech) .headerItem {
    color: var(--whiteColor);
}

.headerHidden .headerItem {
    display: none;
}

.details {
    color: var(--uiPrimaryTextHighlighted);
    font-weight: 400;
    text-transform: uppercase;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.details {
    color: rgba(0, 0, 0, 0.5);
}

:global(.hockeyTech) .details {
    color: var(--whiteColor);
}

:global(.darcula) .details {
    color: var(--uiPrimaryTextHighlighted);
}

:global(.darcula) .details:hover {
    color: #fff;
}

.underline {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 0;

    border-bottom-style: solid;
    border-bottom-color: var(--uiPrimaryTextHighlighted);
    border-bottom-width: 1px;

    cursor: pointer;

    border-bottom: initial;
}

.arrowRight {
    width: 0.45rem;
    height: 0.45rem;

    border-block-style: solid;
    border-width: 0 0 1px 1px;
    border-color: var(--uiPrimaryTextHighlighted);

    font-size: 0;
    line-height: 0;

    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.arrowRight {
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5);
    border-width: 0 0 1px 1px;
}

:global(.hockeyTech) .arrowRight {
    border-color: var(--whiteColor);
}

:global(.darcula) .arrowRight {
    border-color: var(--uiPrimaryTextHighlighted);
}

:global(.darcula) .arrowRight:hover {
    border-color: #fff;
}

.arrowLeft {
    width: 0.45rem;
    height: 0.45rem;

    border: solid #2d6db7;
    border-width: 0 0 2px 2px;

    font-size: 0;
    line-height: 0;

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) translate(-3px, 3px);
}

.arrowLeft {
    border: solid rgba(0, 0, 0, 0.5);
}

.arrowLeftGrey {
    width: 10px;
    height: 10px;

    border: solid #97a3b7;
    border-width: 0 0 2px 2px;

    font-size: 0;
    line-height: 0;

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) translate(-3px, 3px);
}

.arrowLeftHidden {
    width: 10px;
    height: 10px;

    border: solid #ffffff;
    border-width: 0 0 2px 2px;

    font-size: 0;
    line-height: 0;

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) translate(-3px, 3px);
}

.tools {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
    margin-left: auto;

    background-color: #2d6db7;
}

.toolsGrey {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
    margin-left: auto;

    background-color: #97a3b7;
}

.circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 22px;
    height: 22px;

    border-radius: 50%;

    background-color: var(--backgroundColorSecondary);
}

.circleGrey {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 22px;
    height: 22px;

    border-radius: 50%;

    background-color: var(--backgroundColorSecondary);
}

.headerHidden {
    position: relative;
}

.toolsHidden {
    position: absolute;
    top: 0;
    right: 0;
}

.circleHidden {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 22px;
    height: 22px;

    background-color: #d6dae2;
}

.clickable {
    cursor: pointer;
}

.refreshContainer {
    width: 20px;
    height: 20px;
    margin: 0 10px;

    cursor: pointer;
}

.buttonsContainer {
    display: flex;
    align-items: center;
}

.closeButton {
    width: 20px;
    height: 20px;
    margin: 0 10px;

    cursor: pointer;
}

.closeLeft {
    width: 20px;
    height: 3px;

    background: #d6dae2;
    transform: translate(0px, 7px) rotate(45deg);
}

.closeRight {
    width: 20px;
    height: 3px;

    background: #d6dae2;
    transform: translate(0px, 10px) rotate(-45deg);
}

.infoContainer {
    display: flex;
    align-items: center;
    width: 24px;
    height: 23px;
    padding: 0 9px 0;

    border-radius: 12px;

    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    text-align: center;

    background: transparent;
    /*transform: translate(-16px);*/
    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;
}

.infoContainer {
    color: #4d4d4d;
}

:global(.darcula) .infoContainer {
    color: white;
}

.infoContainer:hover {
    color: #fff;

    background: var(--uiPrimaryColorActive);
}

.tooltip {
    width: 200px;
    padding: 0 9px 1px 7px;
}

.infoParagraph {
    color: #a6b0c4;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

:global(.darcula) .infoParagraph {
    font-weight: 400;
}

.infoTitle {
    color: var(--uiPrimaryColorActive);
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
}

@media print {
    .hideHeaderOnPrint,
    .activeTab,
    .tabTitle,
    .infoContainer,
    .details {
        display: none;
    }

    .frame {
        display: block;

        border-radius: 0;
    }

    .header {
        border-bottom: 1px solid rgba(112, 112, 112, 0.1);

        background: none;
    }

    .titleHeader {
        color: #1f232e;
    }

    .content {
        overflow: visible;
    }
}

.brandContainer {
    display: flex;
    justify-content: center;

    background: #fff;
}

:global(.darcula) .brandContainer {
    background: #26333c;
}
/*stylelint-enable*/

.brand {
    padding: 5px 10px 5px 10px;

    border-radius: 0 0 8px 8px;

    color: #fff;
    font-size: 12px;

    background: var(--uiPrimaryColorActive);
}
