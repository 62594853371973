.star {
    fill: #fcfcfc;
    stroke: #d6dae3;
    stroke-width: 30;
}

:global(.hockeyTech) .star {
    fill: var(--whiteColor);
    stroke: var(--mediumGrayColor);
}

.activeStar {
    fill: var(--uiPrimaryColor);
    stroke: var(--uiPrimaryColor);
    stroke-width: 30;
}
