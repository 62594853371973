.modal {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 720px;
    height: 472px;
    padding: 5px 15px;

    border-radius: 10px;

    background: white;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12);
    transform: translate(-50%, -50%);
}

:global(.darcula) .modal {
    background: #2b3136;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1501;

    background: rgba(0, 0, 0, 0.32);
}

.title {
    color: #245187;
    font-weight: 500;
    font-size: 0.75em;
    font-family: Roboto, 'Open Sans', sans-serif;
}

:global(.darcula) .title {
    color: rgba(255, 255, 255, 0.7);
}

.filesToUploadLabel {
    display: block;
    padding: 5em 3em 2em 3em;

    color: var(--uiSecondaryColorActive);
    font-size: 1em;
    text-align: center;
}

.filesToUpload {
    margin-right: auto;
    margin-left: auto;

    color: var(--uiPrimaryColorActive);
    font-size: 1em;
    text-align: center;
}

.filesToUpload i {
    padding-right: 5px;
    padding-left: 5px;
}

.filesToUploadPlaceholder {
    display: block;

    color: var(--uiSecondaryColorActive);
    font-size: 1em;
    text-align: center;
}

.gameInfoInputContainer {
    display: flex;
    justify-content: space-between;
    width: 660px;
    margin: 25px auto;
}

.input {
    width: 170px;
    height: 30px;
    padding: 0 0.75rem;

    border: 1px solid rgba(0, 0, 0, 0.4);

    color: rgba(0, 0, 0, 0.4);
    font-size: 0.8rem;
    font-family: Roboto, 'Open Sans', sans-serif;
}

.input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

:global(.darcula) .input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

:global(.darcula) .input {
    border: 1px solid rgba(255, 255, 255, 0.4);

    color: rgba(255, 255, 255, 0.4);

    background: #383d49;
}

.input:valid,
.input:focus {
    border: 1px solid var(--uiPrimaryColor);

    color: var(--uiPrimaryColor);
}

.dropzone {
    width: 660px;
    height: 290px;
    margin: 0 auto;
    overflow-y: auto;

    border: 1px dashed;
    border-radius: 10px;

    color: var(--uiPrimaryColorActive);
}

:global(.darcula) .dropzone {
    color: rgba(255, 255, 255, 0.7);
}

.dropzoneDragActive {
    width: 660px;
    height: 290px;
    margin: 0 auto;

    border: 1px dashed;
    border-radius: 10px;

    color: var(--uiPrimaryColorActive);

    background-color: rgba(49, 97, 155, 0.21);

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.progress {
    width: 80%;
    margin-top: 35px;
    margin-right: auto;
    margin-left: auto;
}

.progressBar {
    height: 5px;

    border-radius: 15px;
}

.modal .button {
    display: block;
    width: 170px;
    margin: 25px auto 0;
}

.modal .dropzone .cancel {
    background-color: var(--cancelColor);
}
