.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    overflow: hidden;

    vertical-align: bottom;
}

@media print {
    .icon {
        display: none !important;
    }
}

.placeholder {
    display: none;
}

@media print {
    .placeholder {
        display: block;
    }
}

.search {
}

.teamIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    overflow: hidden;

    border-radius: 50%;
}

.teamIcon {
    width: 22px;
    /*height: 32px;*/
}
