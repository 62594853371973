.relativeWrapper {
    position: relative;
}

@media (max-width: 1024px) {
    .relativeWrapper {
        width: 100%;
    }
}

.relativeWrapper > button {
    width: 100%;
}

@media (min-width: 1025px) {
    .relativeWrapper > button {
        min-width: 200px;
    }

    .relativeWrapperWide > button {
        min-width: 280px;
    }
}

.button {
    padding-inline: 24px;
}

.content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 3000;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;

    border-radius: 12px;

    background-color: var(--backgroundColorSecondary);
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

/*stylelint-disable*/
.content {
    border-radius: 4px;
}

:global(.darcula) .content {
    background-color: #2d3d4d;
}

:global(.darcula) .content input {
    background-color: #2d3d4d;
}

/*stylelint-enable*/
.hidden {
    visibility: hidden;
}

@media (max-width: 500px) {
    .hidden {
        visibility: unset;
    }
}

.panelOpen {
    font-size: 0.75rem;
    text-transform: none;
}

.menu {
    margin: 0;
    padding: 0;

    border: none;

    color: var(--optionsPrimaryColor);
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 2;
    text-transform: uppercase;

    list-style: none;
    outline: none;

    user-select: none;
}

.menu > li {
    cursor: pointer;
}

.menu > li:hover {
    color: var(--uiPrimaryColor);
}

.menu > li:hover:before {
    color: var(--uiPrimaryColor);
    content: '+ ';
}

.selected {
    color: var(--uiPrimaryColor);
}

.ignored {
    color: #707c93;
}

.menu > .ignored:hover:before {
    color: #707c93;
    content: '- ';
}

.menu > .disabled,
.menu > .disabled:hover {
    color: var(--optionsDisabledColor);

    cursor: not-allowed;
}

.menu > .disabled:hover:before {
    content: '';
}

.menu > .selected:hover {
    color: var(--cancelColor);
}

.menu > .selected:hover:before {
    color: var(--cancelColor);
    content: '- ';
}

.bordered {
    margin-right: 0.5rem;
    margin-bottom: 3px;
    padding-bottom: 3px;

    border-bottom: solid 1px var(--optionsPrimaryColor);
}

.searchPanel {
    display: flex;
    margin-right: 0.5rem;
    padding-bottom: 3px;

    border-bottom: solid 1px var(--optionsPrimaryColor);

    font-size: 0.75rem;
}

.searchInput {
    width: 100%;
    margin-left: 0.25rem;

    border: none;

    outline: none;
}

.searchInput::placeholder {
    color: var(--optionsPrimaryColor);
}

/*stylelint-disable*/
:global(.darcula) .searchInput {
    color: rgba(255, 255, 255, 0.8);
}

/*stylelint-enable*/

.controls {
    display: flex;
    justify-content: space-between;
    margin-top: 1px;
    margin-right: 0.5rem;
    padding-top: 3px;

    border-top: solid 1px var(--optionsPrimaryColor);
}

.selectAll,
.deselectAll {
    padding: 3px 3px 0 3px;

    font-weight: 600;
    font-size: 12px;

    cursor: pointer;
}

.selectAll {
    color: var(--uiPrimaryColor);
}

.selectAll:hover {
    color: var(--uiPrimaryColorActive);
}

.deselectAll {
    color: var(--optionsPrimaryColor);
}

.deselectAll:hover {
    color: var(--cancelColor);
}

.metaOptionsContainer {
    margin-right: 0.5rem;
    margin-bottom: 1px;
    padding-bottom: 3px;

    border-bottom: solid 1px var(--optionsPrimaryColor);
}

.metaOption {
    color: var(--optionsPrimaryColor);
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 2;
    text-transform: uppercase;

    cursor: pointer;
}

.metaOption:hover {
    color: var(--uiPrimaryColor);
}

.metaOption:hover:before {
    color: var(--uiPrimaryColor);
    content: '+ ';
}

.metaOption.metaOptionActive {
    color: var(--uiPrimaryColor);
}

.metaOption.metaOptionActive:hover {
    color: var(--cancelColor);
}

.metaOption.metaOptionActive:hover:before {
    color: var(--cancelColor);
    content: '- ';
}

.static-position {
    position: static;
}
