.checkbox {
    display: inline-flex;
    align-items: center;
    width: 14px;
    height: 14px;

    border: 1px solid #a6b0c4;
    border-radius: 2px;

    vertical-align: middle;

    background: #fff;
    /*translate a little for pixel perfect view*/
    transform: translateY(-5%);
}

.checkbox:focus {
    outline: none;
    box-shadow: 0 0 2px #a6b0c4;
}

.checkbox_halfChecked {
    border: none;

    background: #b0b0b0;
}

.checkbox_checked {
    border: none;

    background: var(--uiPrimaryColor);
}

.disabled .checkbox_checked {
    background: gray;
}

.disabled {
    color: gray;
}

.check {
    width: 100%;
    height: 100%;
}
