/* Legacy bootstrap styles */
/*stylelint-disable*/
html {
    font-family: sans-serif;
    line-height: 1.15;
    width: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
/*stylelint-enable*/

body {
    width: 100%;
    min-width: fit-content;
    margin: 0;
    overflow-x: auto;

    color: var(--defaultColor);
    font-weight: normal;
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;

    background-color: var(--backgroundColorSecondary);
}

article,
aside,
footer,
header,
nav,
section {
    display: block;
}

h1 {
    margin: 0.67em 0;

    font-size: 2em;
}

figcaption,
figure,
main {
    display: block;
}

figure {
    margin: 1em 40px;
}
/*stylelint-disable*/
hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
/*stylelint-enable*/
pre {
    font-size: 1em;
    font-family: monospace, sans-serif;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline-width: 0;
}

abbr[title] {
    border-bottom: none;

    text-decoration: underline dotted;
}

b,
strong {
    font-weight: inherit;
}
/*stylelint-disable*/
b,
strong {
    font-weight: bolder;
}
/*stylelint-enable*/
code,
kbd,
samp {
    font-size: 1em;
    font-family: monospace, monospace;
}

dfn {
    font-style: italic;
}

mark {
    color: #000;

    background-color: #ff0;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;

    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;

    font-size: 100%;
    font-family: sans-serif;
    line-height: 1.15;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}
/*stylelint-disable*/
button,
html [type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}
/*stylelint-enable*/
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;

    border-style: none;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;

    border: 1px solid #c0c0c0;
}
/*stylelint-disable*/
legend {
    display: table;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0;

    color: inherit;
    white-space: normal;
}
/*stylelint-enable*/
progress {
    display: inline-block;

    vertical-align: baseline;
}

textarea {
    overflow: auto;
}
/*stylelint-disable*/
[type='checkbox'],
[type='radio'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
/*stylelint-enable*/
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    /*stylelint-disable*/
    -webkit-appearance: textfield;
    /*stylelint-enable*/
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    /*stylelint-disable*/
    -webkit-appearance: none;
    /*stylelint-enable*/
}

::-webkit-file-upload-button {
    font: inherit;
    /*stylelint-disable*/

    -webkit-appearance: button;
    /*stylelint-enable*/
}

details,
menu {
    display: block;
}

summary {
    display: list-item;
}

canvas {
    display: inline-block;
}

template {
    display: none;
}

[hidden] {
    display: none;
}

@media print {
    *,
    *::before,
    *::after,
    p::first-letter,
    div::first-letter,
    blockquote::first-letter,
    li::first-letter,
    p::first-line,
    div::first-line,
    blockquote::first-line,
    li::first-line {
        text-shadow: none !important;
        /*stylelint-disable*/
        -webkit-box-shadow: none !important;
        /*stylelint-enable*/
        box-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: ' (' attr(title) ')';
    }
    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;

        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    .navbar {
        display: none;
    }
    .badge {
        border: 1px solid #000;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }
}
/*stylelint-disable*/
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}
/*stylelint-enable*/

[tabindex='-1']:focus {
    outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
}

address {
    margin-bottom: 1rem;

    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: bold;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}
/*stylelint-disable*/
a {
    color: #0275d8;
    text-decoration: none;
}

:global(.hockeyTech) a {
    color: var(--uiPrimaryColor);
}
/*stylelint-enable*/
a:focus,
a:hover {
    color: #014c8c;
    text-decoration: underline;
}
/*stylelint-disable*/
:global(.hockeyTech) a:focus,
:global(.hockeyTech) a:hover {
    color: var(--uiPrimaryColorActive);
}
/*stylelint-enable*/
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}
/*stylelint-disable*/
pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
}
/*stylelint-enable*/
[role='button'] {
    cursor: pointer;
}
/*stylelint-disable*/
a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
/*stylelint-enable*/
table {
    border-collapse: collapse;

    background-color: transparent;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    color: #636c72;
    text-align: left;

    caption-side: bottom;
}

th {
    text-align: left;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button:focus {
    outline: 1px dotted;
}
/*stylelint-disable*/
input,
button,
select,
textarea {
    line-height: inherit;
}

input[type='radio']:disabled,
input[type='checkbox']:disabled {
    cursor: not-allowed;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;

    border: 0;
}

legend {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0;

    font-size: 1.5rem;
    line-height: inherit;
}

input[type='search'] {
    -webkit-appearance: none;
}

output {
    display: inline-block;
}

[hidden] {
    display: none !important;
}
/*stylelint-enable*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;

    color: inherit;
    font-weight: 500;
    font-family: inherit;
    line-height: 1.1;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 576px) {
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
}

@media (min-width: 768px) {
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
}

@media (min-width: 992px) {
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
}

@media (min-width: 1400px) {
    .row {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl {
    position: relative;

    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 768px) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 992px) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl {
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 1400px) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-sm-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-sm-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-md-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-md-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-md-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-lg-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-lg-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-lg-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .col-xl {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-xl-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-xl-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    }

    .col-xl-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-xl-11 {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;

    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    color: var(--defaultColor);
    font-size: 1rem;
    line-height: 1.25;

    background-color: var(--backgroundColorSecondary);
    background-image: none;
    background-clip: padding-box;

    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
    border-color: #5cb3fd;

    color: var(--uiPrimaryColorActive);

    background-color: var(--backgroundColorSecondary);
    outline: none;
}

.form-control::placeholder {
    color: #636c72;

    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #eceeef;
    opacity: 1;
}

.form-control:disabled {
    cursor: not-allowed;
}

.btn {
    display: inline-block;
    padding: 0.5rem 1rem;

    border: 1px solid transparent;
    border-radius: 0.25rem;

    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;

    transition: all 0.2s ease-in-out;

    user-select: none;
}

.btn:focus,
.btn:hover {
    text-decoration: none;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(2, 117, 216, 0.25);
}

.btn:disabled {
    cursor: not-allowed;
    opacity: 0.65;
}

.btn:active {
    background-image: none;
}

.btn-secondary {
    border-color: #ccc;

    color: #292b2c;

    background-color: var(--backgroundColorSecondary);
}

.btn-secondary:hover {
    border-color: #adadad;

    color: #292b2c;

    background-color: #e6e6e6;
}

.btn-secondary:focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}

.btn-secondary:disabled {
    border-color: #ccc;

    background-color: var(--backgroundColorSecondary);
}

.btn-secondary:active {
    border-color: #adadad;

    color: #292b2c;

    background-color: #e6e6e6;
    background-image: none;
}

.btn-outline-secondary {
    border-color: #ccc;

    color: #ccc;

    background-color: transparent;
    background-image: none;
}

.btn-outline-secondary:hover {
    border-color: #ccc;

    color: #fff;

    background-color: #ccc;
}

.btn-outline-secondary:focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}

.btn-outline-secondary:disabled {
    color: #ccc;

    background-color: transparent;
}

.btn-outline-secondary:active {
    border-color: #ccc;

    color: #fff;

    background-color: #ccc;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
    width: 100%;
}
