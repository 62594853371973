.inputTime {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.separator {
    margin: 2px;
}

.clear {
    margin: 5px;
}
