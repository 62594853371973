.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.1);
}

.modalComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    border-radius: 10px;

    background: #eee;
}

.modalTitle {
    margin-bottom: 40px;

    font-size: 30px;
}

.modalButton {
    cursor: pointer;
}
