.wrapper {
    position: absolute;
    z-index: 1070;

    display: block;
    min-width: auto;

    font-weight: normal;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    white-space: normal;
    text-transform: none;
    text-decoration: none;
    text-shadow: none;
    word-wrap: break-word;
    word-break: normal;
    word-spacing: normal;

    opacity: 0;

    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    line-break: auto;
}

@media (min-width: 1400px) {
    .wrapper {
        min-width: 220px;
    }
}

.content {
    border-radius: 1rem;

    background-color: #f9f9f9;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.content {
    border-radius: 8px;

    background-color: #0c2449;
}

:global(.darcula) .content {
    background-color: #2d3d4d;
}

:global(.hockeyTech) .content {
    background-color: var(--darkGrayColor);
}

.wrapper.show {
    opacity: 1;
}

.tooltipable {
    cursor: pointer;
}

@media print {
    .wrapper {
        display: none;
    }
}
