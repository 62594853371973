.btnAction {
    display: inline-block;
    margin: 1px 0;
    padding: 9px 10px;

    border: none;
    border-radius: 100px;

    font-weight: 600;
    font-size: 0.75rem;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;

    outline: none;
    cursor: pointer;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    user-select: none;
}

:global(.darcula) .btnAction {
    color: white;

    background: #2d3d4d;
}

.btnAction {
    border-radius: 4px;
}

.btnAction:focus:not([disabled]),
.btnAction:hover:not([disabled]) {
    text-decoration: none;

    outline: none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.btnAction:active:not([disabled]) {
    text-decoration: none;

    outline: none;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.btnAction:hover {
    cursor: pointer;
}
