.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    padding-bottom: 10px;

    border-bottom: 1px solid rgba(112, 112, 112, 0.25);

    color: #6f7b92;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    page-break-before: always;
}

.title,
.logo,
.game {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
}

.logo {
    display: flex;
    justify-content: center;
}

@media print {
    .logoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 100%;
        padding: 12px;

        color: white !important;

        -webkit-print-color-adjust: exact;
    }

    .logoSvg {
        display: block;
        width: auto;
        height: 100%;
    }
}

.logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding: 12px;

    //background: #0e1d28;
}

.title {
    font-size: 24px;
    text-align: left;
}

.logoImg {
    display: block;
    width: 100px;
    margin: 0 auto;
}

.game {
    font-size: 12px;
    text-align: right;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 99vh;
}

.loadingHide {
    display: none;
}

.report {
    width: 1200px;
    /* "display: none" can wrong displayed on print*/
    height: 1px;
    margin: 0 auto;
    overflow: hidden;
}

@media print {
    .loading {
        display: none;
    }

    .report {
        height: auto;
        overflow: inherit;
    }
}

.reportContent {
    height: 1px;

    font-family: Roboto, sans-serif;
    -webkit-print-color-adjust: exact;
}

.reportVisible {
    height: auto;
}
