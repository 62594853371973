.footer {
    z-index: 1002;

    display: flex;
    flex: none;
    align-items: center;
    width: 100%;
    min-height: 48px;

    color: #bdbdbd;
    font-size: 0.7em;
    letter-spacing: 1.2px;

    background: #1e232e;
}

.footer {
    background: var(--uiHeaderColor);
}

:global(.hockeyTech) .footer {
    background: var(--blackColor);
}

:global(.darcula) .footer {
    background: #141c23;
}

.rightContent {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.logo {
    width: 92px;
}

.documents {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.item {
    margin: 14px;

    cursor: pointer;
}

.item:hover {
    color: #fff;
    text-decoration: underline;
}

@media print {
    .footer {
        display: none;
    }
}

.clickable:hover {
    cursor: pointer;
}
